<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12"><span v-if="this.basepathstatus == 3">Search Citizen</span><span v-else>Search Voters</span></div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formLastNameInput" class="form-label"><span v-if="this.basepathstatus == 3">Citizen</span><span v-else>Voter</span> Name<span
                                    class="text-danger">*</span></label>
                            <input type="text" v-model="votername" class="form-control text-capitalize"
                                id="formLastNameInput" placeholder="Enter Name" autocomplete="off" maxlength="50"
                                required />
                            <div class="custom-error" v-if="v$.votername.$error">
                                {{ v$.votername.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formLastNameInput" class="form-label">Father's/Husband's Name<span
                                    class="text-danger">*</span></label>
                            <input type="text" v-model="familyname" class="form-control text-capitalize"
                                id="formLastNameInput" placeholder="Enter Name" autocomplete="off" maxlength="50"
                                required />
                            <div class="custom-error" v-if="v$.familyname.$error">
                                {{ v$.familyname.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Select Age Or Date of Birth<span class="text-danger">*</span></label>
                            <div class="custom-group-radio-box-btn">
                                <input type="radio" class="btn-check" v-model="agedobvalue" name="age-dob-option"
                                    id="agelabel" autocomplete="off" value="1" />
                                <label class="btn custom-radio-box-btn text-capitalize" for="agelabel">Age</label>
                                <input type="radio" class="btn-check" v-model="agedobvalue" name="age-dob-option"
                                    id="dateofbirthlabel" autocomplete="off" value="2" />
                                <label class="btn text-capitalize" for="dateofbirthlabel">Date of Birth</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12" v-if="this.agedobvalue == 1">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Select Age<span
                                    class="text-danger">*</span></label>
                            <Multiselect v-model="age" :options="ageList" :searchable="true" label="label"
                                placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                                :object="true" noOptionsText="No Result" />
                            <div class="custom-error" v-if="v$.age.$error">
                                {{ v$.age.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12" v-if="this.agedobvalue == 2">
                        <div class="custom-form-group">
                            <label for="formBirthDateInput" class="form-label">Date of Birth<span
                                    class="text-danger">*</span></label>
                            <Calendar v-model="birthdate" :maxDate="maxDateBirth" :showIcon="true"
                                class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                :manualInput="false" :monthNavigator="true"
                                :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body" :yearNavigator="true">
                            </Calendar>
                            <div class="custom-error" v-if="v$.birthdate.$error">
                                {{ v$.birthdate.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Gender</label>
                            <Multiselect v-model="gender" :options="genderList" :searchable="false" label="label"
                                placeholder="Select" class="multiselect-custom" :canClear="false" :closeOnSelect="true"
                                :object="true" noOptionsText="No Result" />
                        </div>
                    </div>
                </div>
                <Divider />
                <div class="row mt-4">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Select State<span
                                    class="text-danger">*</span></label>
                            <Multiselect v-model="state" :options="stateList" :searchable="true" label="label"
                                placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                                :object="true" noOptionsText="No Result" @select="getDistByStateId(state)" required />
                            <div class="custom-error" v-if="v$.state.$error">
                                {{ v$.state.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Select District<span
                                    class="text-danger">*</span></label>
                            <Multiselect v-model="district" :options="districtList" :searchable="true" label="label"
                                placeholder="Select" class="multiselect-custom" :canClear="true" :closeOnSelect="true"
                                noOptionsText="No Result" :object="true" required />
                            <div class="custom-error" v-if="v$.district.$error">
                                {{ v$.district.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn form-submit-btn me-2" @click="searchvoterbtn()">
                            <span v-if="!btnloader"><i class="pi pi-search me-1" style="font-size: 0.7rem"></i>Search</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="btnloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                        <button v-if="clearbtnflag" type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear All
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-content-section" v-if="tableenable">
            <div class="form-box-outer mb-0">
                <div class="custom-ultima-datatable">
                    <DataTable :value="searchvoterList" :scrollable="true" columnResizeMode="fit" :paginator="false"
                        :lazy="true" :rowHover="true" :totalRecords="totalRecords" :rows="30" dataKey="aj1"
                        @page="changePage($event)"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        :loading="loading" style="z-index: 1;">
                        <template #empty>No Records found.</template>
                        <template #loading>Loading data. Please wait...</template>
                        <Column field="id" header="#" headerStyle="width: 2%" bodyStyle="width: 2%">
                            <template #body="slotProps">
                                    <div>{{ slotProps.index + 1 }}</div>
                            </template>
                        </Column>
                        <Column field="name-id" header="Name / EPIC No." headerStyle="width: 23%" bodyStyle="width: 23%">
                            <template #body="{ data }">
                                <div>
                                    <div>{{ data.name }}</div>
                                    <div>{{ data.name_v1 }}</div>
                                    <div title="EPIC No.">{{ data.epic_no }}</div>
                                </div>
                            </template>
                        </Column>
                        <Column field="age" header="Age" headerStyle="width: 5%" bodyStyle="width: 5%">
                            <template #body="{ data }">
                                <div>
                                    <div>{{ data.age }}</div>
                                    <div>{{ data.gender }}</div>
                                </div>
                            </template>
                        </Column>
                        <Column field="relativename" header="Relative's Name" headerStyle="width: 15%"
                            bodyStyle="width: 15%">
                            <template #body="{ data }">
                                <div>
                                    <div>{{ data.relation_name }}</div>
                                    <div>{{ data.rln_name_v1 }}</div>
                                </div>
                            </template>
                        </Column>
                        <Column field="state" header="Polling Station" headerStyle="width: 35%" bodyStyle="width: 35%">
                            <template #body="{ data }">
                                <div>
                                    <div title="Polling Station Name">{{ data.polling_station_name }}</div>
                                    <div>
                                        <span title="Assembly Name">{{ data.assembly_name }} ({{ data.assembly_number }})</span><span
                                            class="px-2">|</span>
                                        <span title="Parliamentary Name">{{ data.parliamentary_name }} ({{ data.part_no }})</span>
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column field="state" header="Area / District / State" headerStyle="width: 20%" bodyStyle="width: 20%">
                            <template #body="{ data }">
                                <div>
                                    <div><span title="Area">{{ data.area }}</span><span class="px-2">|</span><span
                                            title="District">{{ data.district }}</span></div>
                                    <div>{{ data.state }}</div>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventService from '../../service/EventService';
import ApiService from "../../service/ApiService";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            votername: '',
            familyname: '',
            agedobvalue: 1,
            age: "",
            ageList: [],
            gender: "",
            genderList: [
                { value: "1", label: "Male" },
                { value: "2", label: "Female" },
                { value: "3", label: "Others" },
            ],
            birthdate: null,
            state: "",
            stateList: [],
            district: "",
            districtList: [],
            btnloader: false,
            searchvoterList: [],
            totalRecords: 0,
            loading: false,
            page_no: 0,
            tableenable: false,
            maxDateBirth: moment().subtract(18, "years")._d,
            clearbtnflag: false,
            baseUrl :window.location.origin,
            basepathstatus: 0,
        };
    },
    validations() {
        return {
            votername: {
                required: helpers.withMessage("Please enter voter name", required),
            },
            familyname: {
                required: helpers.withMessage("Please enter father's/husband's name", required),
            },
            state: {
                required: helpers.withMessage("Please select state", required),
            },
            district: {
                required: helpers.withMessage("Please select district", required),
            },
            age: {
                requiredIf: helpers.withMessage(
                    "Please select age",
                    requiredIf(
                        this.agedobvalue == 1
                    )
                ),
            },
            birthdate: {
                requiredIf: helpers.withMessage(
                    "Please select date of birth",
                    requiredIf(
                        this.agedobvalue == 2
                    )
                ),
            },
        };
    },
    ApiService: null,
    eventService: null,
    created() {
        this.ApiService = new ApiService();
        this.eventService = new EventService();
    },
    mounted() {
        this.getEvents();
        this.getstates();
        if (this.baseUrl == 'https://vc.nextedgecivictech.in') {
      this.basepathstatus = 2;
    } else if (this.baseUrl == 'https://demo.cm-connect.in'){
      this.basepathstatus = 3;
    } else {
      this.basepathstatus = 1;
    }
    },
    methods: {
        getstates() {
            this.ApiService.getstates().then((data) => {
                if (data.success == true) {
                    this.stateList = data.data;

                }
            });
        },
        getDistByStateId(e) {
            this.district = '';
            this.ApiService.getdistricts({ state: e.value }).then((data) => {
                if (data.success == true) {
                    this.districtList = data.data;
                }
            });
        },
        getEvents(e) {
            this.eventService.getEvents(e).then((data) => {
                this.ageList = data;
            });
        },
        searchvoterbtn() {
            this.v$.$validate();
            let fields = {};
            fields["voter_name"] = this.votername;
            fields["parent_name"] = this.familyname;
            if (this.birthdate) {
                fields['dob'] = moment(this.birthdate).format('YYYY-MM-DD');
            }
            fields["age"] = this.age.value;
            fields["gender"] = this.gender.label;
            fields["state"] = this.state.label;
            fields["dist"] = this.district.label;
            if (!this.v$.$error) {
                this.btnloader = true;
                this.ApiService.submitvoterdata(fields).then((data) => {
                    if (data.success == true) {
                        this.btnloader = false;
                        this.tableenable = true;
                        this.searchvoterList = data.records;
                        this.loading = false;
                        this.clearbtnflag = true;
                    }
                    else {
                        this.searchvoterList = null;
                        this.totalRecords = 0;
                        this.btnloader = false;
                        this.loading = false;
                        this.tableenable = true;
                        this.clearbtnflag = true;
                    }
                });
            } else {
                this.$error;
            }
        },
        searchvoterclearbtn() {
            this.votername = '';
            this.familyname = '';
            this.age = '';
            this.gender = '';
            this.birthdate = null;
            this.state = '';
            this.district = '';
            this.searchvoterList = null;
            this.tableenable = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.form-submit-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 25px;
    font-family: "AcuminPro-Regular";
    min-width: 110px;
}

.form-submit-btn:focus {
    box-shadow: none;
}

.custom-ultima-datatable {
    height: 100% !important;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
</style>
